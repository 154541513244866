import en from "./en";

const de= Object.assign({}, en);

const FourElementsInstructionsDE = {
    title: '4 Elements Inventory',
    instructions1: '<p class="mb-2">Willkommen beim 4Elements Persönlichkeitsinventar.</p>' +
        '<p class="mb-2">Der Test wird Ihnen nach und nach die Aussagen über sich selbst zeigen. Sie können wählen, ob Sie ihnen zustimmen oder sie ablehnen.</p>' +
        '<p class="mb-2">Da es sich um einen Persönlichkeitstest handelt, gibt es KEINE RICHTIGEN oder FALSCHEN Antworten. Am besten sind die Antworten, die Ihnen als erstes in den Sinn kommen, und Sie müssen nicht lange darüber nachdenken.</p>'+
        '<p class="mb-2">Bitte wählen Sie die Option "ICH WEISS NICHT" NUR IN AUSNAHMEFÄLLEN, wenn es für Sie extrem schwierig oder unmöglich ist, sich zu entscheiden.</p>'+
        '<p>Für das Ausfüllen des Fragebogens benötigen Sie etwa 15-20 Minuten.</p>',
    instructions2: '<p>Bewerten Sie die folgenden Aussagen danach, ob sie auf Sie zutreffen oder nicht. Wählen Sie die Option "Ich weiß nicht" nur ausnahmsweise, wenn es für Sie extrem schwierig oder unmöglich ist, zuzustimmen oder abzulehnen.</p>',
    next_questions: 'Mehr Fragen',
}

const HexacoInstructionsDE = {
    title: 'HEXACO',
    instructions2: '',
    next_questions: 'Mehr Fragen',
}


de.questionnaire = {
    '4e-development': FourElementsInstructionsDE,
    '4e-personal': FourElementsInstructionsDE,
    '4e-summary': FourElementsInstructionsDE,
    'hexaco-main': HexacoInstructionsDE,
}

export default de
