import ReportTierEnum from '../components/Report/ReportTierEnum.js'
import en from "./en";

const FourElementsInstructionsCZ = {
    title: '4 Elements Inventory',
    instructions1: '<p class="mb-2">Vítáme Vás u vyplňování dotazníku <strong>4Elements Inventory</strong>.</p>' +
            '<p class="mb-2">V testu se Vám budou postupně zobrazovat tvrzení, u kterých budete zaznamenávat, zda s nimi SOUHLASÍTE, nebo NESOUHLASÍTE.</p>' +
            '<p class="mb-2">Protože se jedná o test osobnostní, NEJSOU v něm žádné SPRÁVNÉ ani NESPRÁVNÉ odpovědi. Nejlepší bývají ty, které Vás napadnou jako první, a nemusíte o nich příliš přemýšlet.</p>'+
            '<p class="mb-2">Možnost NEVÍM prosím VOLTE POUZE VÝJIMEČNĚ, kdy pro Vás bude mimořádně těžké či nemožné se rozhodnout.</p>'+
            '<p>Vyplnění dotazníku Vám <strong>zabere přibližně 15-20 minut</strong>. Po dokončení testu odešleme během několika následujících minut Váš osobní report do Vaší emailové schránky.</p>',
    instructions2: '<p>Hodnoťte následující výroky podle toho, zda na Vás sedí, či nesedí. Možnost „nevím“ volte pouze zcela výjimečně, když pro Vás je extrémně těžké či nemožné přiklonit se k souhlasu či nesouhlasu.</p>',
    next_questions: 'Další otázky',
}

const FiveAspectsInstructionsCZ = {
    title: 'Pět aspektů všímavosti',
    instructions1: '<p class="mb-2">Vítáme Vás při vyplňování dotazníku <strong>Pět aspektů všímavosti</strong>,</p>' +
        '<p class="mb-2">postupně Vám budeme předkládat výroky a Vašim úkolem bude hodnotit, do jaké míry s ním souhlasíte. </p>' +
        '<p class="mb-2">Většině lidí vyplnění dotazníku <strong>trvá přibližně 10 až 15 minut</strong>. Pokuste se najít klidnou chvíli a dotazník nerušeně vyplnit najednou.</p>' +
        '<p><strong>Vaše odpovědi budou ukládány průběžně</strong>, pokud práci z jakéhokoli důvodu budete muset přerušit, budete moci po opětovném otevření odkazu pro vyplňování navázat tam, kde jste přestali.</p>',
    instructions2: '<p>Přečtěte si prosím následující tvrzení a označte na škále, do jaké míry dané tvrzení pro vás všeobecně platí.</p>',
    next_questions: 'Další otázky',
}

const HexacoInstructionsCZ = {
    title: 'HEXACO',
    instructions1: '<p class="mb-2">Vítáme Vás při vyplňování dotazníku <strong>HEXACO</strong>,</p>' +
        '<p class="mb-2">postupně Vám budeme předkládat výroky a Vašim úkolem bude hodnotit, do jaké míry s ním souhlasíte. Protože se jedná o test osobnostní, NEJSOU v něm žádné SPRÁVNÉ ani NESPRÁVNÉ odpovědi. Nejlepší bývají ty, které Vás napadnou jako první, a nemusíte o nich příliš přemýšlet.</p>' +
        '<p class="mb-2">Většině lidí vyplnění dotazníku <strong>trvá přibližně 20 minut</strong>. Pokuste se najít klidnou chvíli a dotazník nerušeně vyplnit najednou.</p>' +
        '<p><strong>Vaše odpovědi budou ukládány průběžně</strong>, pokud práci z jakéhokoli důvodu budete muset přerušit, budete moci po opětovném otevření odkazu pro vyplňování navázat tam, kde jste přestali.</p>',
    instructions2: '<p>Hodnoťte, prosím, následující výroky podle toho, do jaké míry s ním souhlasíte, do jaké míry na Vás sedí.</p>' +
        '<p>Protože se jedná o <strong>test osobnostní, NEJSOU v něm žádné SPRÁVNÉ ani NESPRÁVNÉ odpovědi</strong>. Nejlepší bývají ty, které Vás napadnou jako první, a nemusíte o nich příliš přemýšlet.</p>',
    next_questions: 'Další otázky',
}

const cz = {
    // app info
    app: {
        loading: {
            title: 'Nahrávám...',
            message: 'Nahrávání může chvilku trvat. Prosíme, nezavírejte tuto stránku.',
        },
        loadingData: 'Prosím, čekejte než načteme potřebná data...',
        loadingReport: {
            title: 'Načítám výsledky...',
            message: 'Prosím, čekejte než načteme výsledky.'
        },
        saving: {
            title: 'Ukládám...',
            message: 'Nahrávání může chvilku trvat. Prosíme, nezavírejte tuto stránku.',
        },
    },
    // registration form
    registrationForm: {
        name: {
            label: 'Jméno',
            placeholder: 'Prosíme, vyplňte celé jméno'
        },
        email: {
            label: 'Email',
            placeholder: 'Prosíme, vyplňte platný email'
        },
        preferredLanguage: {
            label: 'Preferovaný jazyk',
        },
        tos: {
            label: 'Souhlasím s podmínkami užívání',
            url: '/tos_en.pdf',
        },
        gdpr: {
            label: 'Souhlasím se zpracováním dat',
            url: '/gdpr_en.pdf',
        },
        submit: 'Registrovat',
    },
    // Questionnaire
    questionnaire: {
        '4e-development': FourElementsInstructionsCZ,
        '4e-personal': FourElementsInstructionsCZ,
        '4e-summary': FourElementsInstructionsCZ,
        'five-aspects-main': FiveAspectsInstructionsCZ,
        'hexaco-main': HexacoInstructionsCZ,
    },
    elements: {
        air: 'Vzduch',
        earth: 'Země',
        fire: 'Oheň',
        water: 'Voda'
    },
    // Pages
    page: {
        reportEvaluated: {
            title: 'osobní vyhodnocení',
            comingSoon: 'Připravuje se',
            text: 'Vaše odpovědi právě vyhodnocujeme a jakmile budeme mít výsledky, zašleme vám upozornění.',
        }
    },
    // report
    report: {
        intro: {
            headline: 'Výsledky testu',
            guide: 'Guide to your personality landscape',
            text: 'We keep it simple while considering each aspect of your personality to give you the utmost beneficial insights into your behavior, motives and passions.'
        },
        opening: {
            title: {
                male: 'Dear',
                female: 'Dear',
                undisclosed: 'Dear'
            }
        },
        elementalTraits: {
            headline: 'Introduction to <strong class="font-extrabold">elemental traits</strong>',
            traits: {
                air: '<ul>\n' +
                    '<li>Ambitious</li>\n' +
                    '<li>Goal oriented</li>\n' +
                    '<li>Energy and initiative</li>\n' +
                    '<li>Leadership</li>\n' +
                    '<li>Motivational skills</li>\n' +
                    '<li>Assertiveness</li>\n' +
                    '<li>Strategic thinking</li>\n' +
                    '</ul>',
                earth: '<ul>\n' +
                    '<li>Analytic skills</li>\n' +
                    '<li>Organized planner</li>\n' +
                    '<li>Dependable</li>\n' +
                    '<li>Detail oriented</li>\n' +
                    '<li>Can handle stress</li>\n' +
                    '<li>Stays calm and stable</li>\n' +
                    '<li>Critical thinker</li>\n' +
                    '</ul>',
                fire: '<ul>\n' +
                    '<li>Communication skills</li>\n' +
                    '<li>Change oriented</li>\n' +
                    '<li>Ability to put forward a vision</li>\n' +
                    '<li>Flexibility</li>\n' +
                    '<li>Creativity and innovation</li>\n' +
                    '<li>Team player</li>\n' +
                    '<li>Tolerant of uncertainties</li>\n' +
                    '</ul>',
                water: '<ul>\n' +
                    '<li>Emotional intelligence</li>\n' +
                    '<li>Ability to listen</li>\n' +
                    '<li>Diplomacy and tact</li>\n' +
                    '<li>Relationship oriented</li>\n' +
                    '<li>Giving feedback</li>\n' +
                    '<li>Client focused</li>\n' +
                    '</ul>',
            }
        },
        howToRead: {
            title: 'Jak číst výsledky testu?',
            text: '<p>In the view of personality typology, each particular element corresponds with groups of\n' +
                'character traits that affect and, to a large extent, determine our conduct, both in private and\n' +
                'professional life.</p><p>By reading this report, not only do you get valuable insight into your dominant personality traits,\n' +
                'but you also receive several suggestions for your self-development and personality growth. The\n' +
                'report features your strengths and recommendations for your personal development related to\n' +
                'your most dominant personality element.</p><p>Unlike performance tests the 4 Elements personality test does not describe any specific result\n' +
                'as better or worse than others. The questionnaire results reveal your personality inclination and\n' +
                'related strengths and areas for development.</p>'
        },
        personalityRatio: {
            headline: 'Ratio of elements in your personality',
            figure: 'Relative ration of individual elements in your personality profile',
            text: 'The numbers show the relative balance of individual elements in your personality profile. In terms\n' +
                'of overall personality inclination, this ratio is important as it predicts which element(s) will\n' +
                'dominate your personality.'
        },
        populationComparison: {
            headline: 'Comparison of your profile with population',
            figure: 'Comparison of your elements against general population',
            textTop: 'The numbers are shown in percentile. Percentile indicates the number of people from a representative sample whose score was lower than yours or the same. For example, suppose\n' +
                'your result on the scale of a particular element corresponds with the 60th percentile. In that case, it means that 60 percent of people have personality traits related to that element as\n' +
                'prominent as you or less, while 40 percent of the people will have these traits more prominent.',
            textBottom: 'As stated above, the report does not describe any specific result as better or worse than others. However, we should always be more sensitive about extreme scores. Having a very\n' +
                'high representation of the given element can bring many qualities, which can be at the same time too intensive and overrule all others. Having a too-low representation of the given\n' +
                'element can, on the other hand, indicate that we have some blind spots, and we might consider developing this element so that we can apply the qualities of the given element when\n' +
                'the situation deserves it.',
        },
        graphs: {
            title: {
                pie: 'Balance of <strong>elements</strong> in your <strong>personality</strong>',
                bar: '<strong>Comparison</strong> of your elements with <strong>population</strong>',
            },
            text: 'The numbers show the relative balance of individual elements in your personality profile. In terms\n' +
                'of overall personality inclination, this ratio is important as it predicts which element(s) will\n' +
                'dominate your personality.',
            fig1: 'Relative balance of individual elements in your personality profile',
            fig2: 'Comparison of your elements against general population',
            text2: 'The numbers are shown in percentile. Percentile indicates the number of people from a representative sample whose score was lower than yours or the same. For example, suppose\n' +
                'your result on the scale of a particular element corresponds with the 60th percentile. In that case, it means that 60 percent of people have personality traits related to that element as\n' +
                'prominent as you or less, while 40 percent of the people will have these traits more prominent.',
            text3: 'As stated above, the report does not describe any specific result as better or worse than others. However, we should always be more sensitive about extreme scores. Having a very\n' +
                'high representation of the given element can bring many qualities, which can be at the same time too intensive and overrule all others. Having a too-low representation of the given\n' +
                'element can, on the other hand, indicate that we have some blind spots, and we might consider developing this element so that we can apply the qualities of the given element when\n' +
                'the situation deserves it.',
            text4: 'As stated above, the report does not describe any specific result as better or worse than others. However, we should always be more sensitive about extreme scores. Having a very\n' +
                'high representation of the given element can bring many qualities, which can be at the same time too intensive and overrule all others. Having a too-low representation of the given\n' +
                'element can, on the other hand, indicate that we have some blind spots, and we might consider developing this element so that we can apply the qualities of the given element when\n' +
                'the situation deserves it.',
        },
        fourAreas: {
            headline: '<strong class="font-extrabold">Your results</strong> in four areas',
            description: 'What is your source of energy, or how do you handle demanding situations? Through this report, you can become more aware of your behavior and tendencies\n' +
                'in four life-important areas. In this part, you can learn more about your way of communication, your preferred style of work, motivators, or your coping strategies\n' +
                'in stressful situations.',
            box: {
                communication: 'Communication',
                styleOfWork: 'Style of work',
                motivation: 'Motivation',
                stressManagement: 'Stress management'
            },
            title: {
                communication: 'Your <strong class="font-extrabold">Communication</strong>',
                styleOfWork: 'Your <strong class="font-extrabold">Style of work</strong>',
                motivation: 'Your <strong class="font-extrabold">Motivation</strong>',
                stressManagement: 'Your <strong class="font-extrabold">Stress management</strong>',
            }
        },
        elementsDevelopment: {
            air: 'Develop qualities of <strong>AIR</strong>',
            earth: 'Develop qualities of <strong>EARTH</strong>',
            fire: 'Develop qualities of <strong>FIRE</strong>',
            water: 'Develop qualities of <strong>WATER</strong>',
        },
        elementsToDevelop: {
            headline: 'How to <strong>develop further</strong>',
            text_left: 'In our lives, we all encounter\n' +
                'various situations and people who\n' +
                'are different in their ways of\n' +
                'thinking and behavior. In terms of\n' +
                'interpersonal relationships and when\n' +
                'solving multiple life problems, the\n' +
                'person who can use a wide variety\n' +
                'of possibilities in communication,\n' +
                'behavior, and thinking is in advance.',
            text_right: 'Therefore, it can be interesting to\n' +
                'contemplate the ways of thinking\n' +
                'and behavior you don\'t standardly\n' +
                'use as they are not typical of you.\n' +
                'In the following pages, you will\n' +
                'find recommendations on\n' +
                'developing qualities associated\n' +
                'with the individual elements and\n' +
                'gain additional inspiration for\n' +
                'expanding your behavior\n' +
                'repertoire for various situations.'
        },
        aboutUs: {
            headline: '<strong>About</strong> us',
            author: {
                name: 'As. Prof. Radvan Bahbouh - Author of 4 Elements INVENTORY®',
                bio: '4The original personality typology developed by our company is based on more than\n' +
                    'ten years of research work; it makes use of understandable metaphors of the four\n' +
                    'elements (fire, water, earth, air) to describe human character traits – thus, it uses an\n' +
                    'empirical basis as well as intuitive forms. We can convey the typology by means of the\n' +
                    '4Elements Personality Inventory® questionnaire, and also through our own unique\n' +
                    'training and Train the Trainers programmes. Thanks to the unique format of 4 Elements,\n' +
                    'it is possible to embrace such topics as self-knowledge and personality typology,\n' +
                    'communication, leadership and motivation of people, setting corporate culture, trade\n' +
                    'and sales, and many others in a non-traditional and usable way.'
            },
            company: {
                bio: '<p>\n' +
                    '<strong>QED GROUP</strong> is a team of highly qualified enthusiasts\n' +
                    'in the applied psychology and behavioural economics fields.\n' +
                    'We provide companies and individuals with innovative approaches\n' +
                    'and methods in personal, team and organisational development.\n' +
                    '</p>'
            }
        },
        // by type
        free: {
            elementPage: {
                air: {
                    headline: 'Person with prevailing element of <strong>AIR</strong>',
                    text: 'People with a dominant <strong>AIR</strong> element are very open to their environment and have excellent' +
                        '<strong>social</strong> skills. They are skilled <strong>communicators</strong> and comfortable in social situations, allowing' +
                        'them to establish <strong>relationships</strong> with others quickly. They often have a wide range of interests' +
                        'and enjoy actively seeking out new information about current events. Another typical trait' +
                        'is their ability to connect individual thoughts in unique ways. In both their personal' +
                        'and professional life, they are very open to <strong>novelty</strong> and like to <strong>experiment</strong>. In group settings,' +
                        'they frequently come up with new ideas, playing a role of an <strong>innovator</strong>. Thanks to their' +
                        '<strong>optimistic</strong> nature, they can lighten up difficult situations with their sense of <strong>humour</strong>.',
                },
                earth: {
                    headline: 'Person with prevailing element of <strong>EARTH</strong>',
                    text: 'People with predominant <strong>EARTH</strong> elements are <strong>systematic</strong> and <strong>well--organized</strong>. They tend to <strong>plan</strong>' +
                        'everything; they like to promote <strong>order</strong> in both their professional and personal lives. <strong>Rules</strong>' +
                        'are essential –they follow them and expect others to do the same. At work, they can often push' +
                        'projects to a successful completion thanks to their <strong>persistence</strong>, attention to <strong>detail</strong> and high' +
                        'standards of <strong>quality</strong>. In a social context, they are usually very <strong>stable</strong> and like to build' +
                        '<strong>long--term</strong> relationships. They are often perceived as <strong>trustworthy</strong>, reliable people who fulfil' +
                        'their obligations and always keep their promises. They do not like to be under time pressure.' +
                        'When it comes to making a decision, they always seek to gather all the available <strong>information</strong>' +
                        'and consider every possible option.',
                },
                fire: {
                    headline: 'Person with prevailing element of <strong>FIRE</strong>',
                    text: 'A person with a dominant <strong>FIRE</strong> element tends to be <strong>energetic</strong>, <strong>self-‐confident</strong>' +
                        'and <strong>goal-‐oriented</strong>. They usually seek and value <strong>personal success</strong> in many aspects,' +
                        'ranging from professional careers to sports and hobbies. They are typically very' +
                        'motivated by <strong>competitive</strong> environments. Since success is an essential theme for them,' +
                        'they frequently strive for <strong>high-status</strong> positions and personal <strong>prestige</strong>. When in a group,' +
                        'they often take the <strong>initiative</strong> and seek to <strong>dominate</strong> the discussion. Thanks to their' +
                        'energy and dedication, they tend to be very <strong>persuasive</strong>, particularly when motivating' +
                        'others to take action. They do not hesitate to <strong>fight</strong> hard for the given cause.',
                },
                water: {
                    headline: 'Person with prevailing element of <strong>WATER</strong>',
                    text: 'People with a strong affinity for the <strong>WATER</strong> element are oriented to seek' +
                        'positive <strong>relationships</strong> and create <strong>harmony</strong> in their environments. They often show a distinct' +
                        '<strong>sensitivity</strong> towards social causes. When making decisions, they give a high priority' +
                        'to <strong>interpersonal</strong> aspects of the problem. Quite often, they are willing to <strong>sacrifice</strong> their' +
                        'interests for the benefit of others (family, friends, colleagues, etc.). They make good' +
                        '<strong>listeners</strong> and have a natural ability to create an atmosphere of <strong>understanding</strong> and <strong>acceptance.</strong>' +
                        'In a group, they often promote positive relationships and a <strong>sincere</strong> attitude towards others.' +
                        'In situations of conflict, they prefer to settle things <strong>peacefully</strong> by using a tactful' +
                        'and <strong>diplomatic</strong> approach.',
                },
            },
            fourElements: {
                headline: 'About 4Elements',
                text: 'More than two and a half thousand years ago, the origin of the world and the differences between people began to be explained by relations(hip) of the four natural elements. This interpretation has permeated all languages, and therefore our thinking, for centuries as a natural metaphor. After twenty-five years of research, our team has been able to demonstrate that we can reliably and validly explain much of what is repetitive in our behaviour and experience by four mathematically provable factors whose qualities correspond to the elements. Thus, the Four Elements Inventory has met the American Psychological Association\'s Standards for Psychological and Educational Testing requirements for developers and publishers of psychological tests.',
                ctaHeading: 'Are you interested in getting your fully personalized development report?',
                ctaText: 'Yes, I want more information',
            }
        },
    },
    // profile form
    profileForm: {
        questionnaireFinishedMessage: '<p class="mb-2">Děkujeme Vám za vyplnění dotazníku, Vaše odpovědi byly úspěšně uloženy.</p>' +
            '<p>Podpořte náš výzkum a vyplňte níže pár informací o sobě. Vaše data budou pro výzkumné účely vždy anonymizována.</p>',
        yearOfBirth: 'Rok narození',
        country: 'Země',
        countryChoice: 'Prosíme, zvolte zemi',
        ethnicity: 'Etnický původ',
        ethnicityChoice: 'Prosíme, zvolte původ',
        gender: 'Pohlaví',
        genderChoice: 'Prosíme, zvolte pohlaví',
        firstLanguage: 'Mateřský jazyk',
        firstLanguageChoice: 'Prosíme, zvolte jazyk',
        education: 'Jaké je vaše nejvyšší dosažené vzdělání?',
        educationChoice: 'Prosíme, zvolte vzdělání',
        workExperience: 'Jak dlouhá je vaše praxe?',
        workExperienceChoice: 'Prosíme, zvolte délku praxe',
        currentJob: 'Jaké je vaše současné povolání?',
        jobArea: 'V jaké oblasti pracujete?',
        jobAreaChoice: 'Prosíme, zvolte oblast',
        contractType: 'Jaký typ smlouvy máte v současném zaměstnání?',
        contractTypeChoice: 'Prosíme, zvolte typ smlouvy',
        save: 'Uložit profil',
        savedMessage: '<p class="mb-2">Děkujeme za vyplnění! Zprávu z dotazníku Vám buď zašleme emailem, nebo Vám bude předána v rámci osobního setkání.</p>' +
            '<p>Přejeme Vám krásný den!</p>',
    },
    // Pricing
    pricing: {
        personal: {
            title: 'Personal profile',
            price: '€19',
            featured: true,
            features: [
                'Your main strengths and talents',
                'Your personality detailed description',
            ],
        },
        development: {
            title: 'Development profile',
            price: '€59',
            featured: false,
            features: [
                'Your main strengths and talents',
                'Your main development areas',
                'Your personality detailed description',
                'Personalized development recommendations',
                'Comparison with population',
            ],
        },
        developmentUpgrade: {
            title: 'Development profile',
            price: '€40',
            featured: false,
            features: [
                'Your main strengths and talents',
                'Your main development areas',
                'Your personality detailed description',
                'Personalized development recommendations',
                'Comparison with population',
            ],
        },
        team: {
            title: 'Team profile',
            price: 'From €9/person',
            featured: false,
            features: [
                'Your team description',
                'Team diversity map',
                'Comparison with similar teams',
            ],
        },
    },
}


// Add Summary tier data
cz.report[ReportTierEnum.TIER_SUMMARY] = {
    elementPage: {
        air: {
            headline: 'Person with prevailing element of <strong>AIR</strong>',
            text: ['People with a dominant <strong>AIR</strong> element are very open to their environment and have excellent' +
            ' <strong>social</strong> skills. They are skilled <strong>communicators</strong> and comfortable in social situations, allowing' +
            ' them to establish <strong>relationships</strong> with others quickly. They often have a wide range of interests' +
            ' and enjoy actively seeking out new information about current events. Another typical trait' +
            ' is their ability to connect individual thoughts in unique ways. In both their personal' +
            ' and professional life, they are very open to <strong>novelty</strong> and like to <strong>experiment</strong>. In group settings,' +
            ' they frequently come up with new ideas, playing a role of an <strong>innovator</strong>. Thanks to their' +
            ' <strong>optimistic</strong> nature, they can lighten up difficult situations with their sense of <strong>humour</strong>.'],
        },
        earth: {
            headline: 'Person with prevailing element of <strong>EARTH</strong>',
            text: ['People with predominant <strong>EARTH</strong> elements are <strong>systematic</strong> and <strong>well--organized</strong>. They tend to <strong>plan</strong>' +
            ' everything; they like to promote <strong>order</strong> in both their professional and personal lives. <strong>Rules</strong>' +
            ' are essential –they follow them and expect others to do the same. At work, they can often push' +
            ' projects to a successful completion thanks to their <strong>persistence</strong>, attention to <strong>detail</strong> and high' +
            ' standards of <strong>quality</strong>. In a social context, they are usually very <strong>stable</strong> and like to build' +
            ' <strong>long--term</strong> relationships. They are often perceived as <strong>trustworthy</strong>, reliable people who fulfil' +
            ' their obligations and always keep their promises. They do not like to be under time pressure.' +
            ' When it comes to making a decision, they always seek to gather all the available <strong>information</strong>' +
            ' and consider every possible option.'],
        },
        fire: {
            headline: 'Person with prevailing element of <strong>FIRE</strong>',
            text: ['A person with a dominant <strong>FIRE</strong> element tends to be <strong>energetic</strong>, <strong>self-‐confident</strong>' +
            ' and <strong>goal-‐oriented</strong>. They usually seek and value <strong>personal success</strong> in many aspects,' +
            ' ranging from professional careers to sports and hobbies. They are typically very' +
            ' motivated by <strong>competitive</strong> environments. Since success is an essential theme for them,' +
            ' they frequently strive for <strong>high-status</strong> positions and personal <strong>prestige</strong>. When in a group,' +
            ' they often take the <strong>initiative</strong> and seek to <strong>dominate</strong> the discussion. Thanks to their' +
            ' energy and dedication, they tend to be very <strong>persuasive</strong>, particularly when motivating' +
            ' others to take action. They do not hesitate to <strong>fight</strong> hard for the given cause.'],
        },
        water: {
            headline: 'Person with prevailing element of <strong>WATER</strong>',
            text: ['People with a strong affinity for the <strong>WATER</strong> element are oriented to seek' +
            ' positive <strong>relationships</strong> and create <strong>harmony</strong> in their environments. They often show a distinct' +
            ' <strong>sensitivity</strong> towards social causes. When making decisions, they give a high priority' +
            ' to <strong>interpersonal</strong> aspects of the problem. Quite often, they are willing to <strong>sacrifice</strong> their' +
            ' interests for the benefit of others (family, friends, colleagues, etc.). They make good' +
            ' <strong>listeners</strong> and have a natural ability to create an atmosphere of <strong>understanding</strong> and <strong>acceptance.</strong>' +
            ' In a group, they often promote positive relationships and a <strong>sincere</strong> attitude towards others.' +
            ' In situations of conflict, they prefer to settle things <strong>peacefully</strong> by using a tactful' +
            ' and <strong>diplomatic</strong> approach.'],
        },
    },
    fourElements: {
        headline: 'About <strong class="font-extrabold">4Elements</strong>',
        text: 'More than two and a half thousand years ago, the origin of the world and the differences between people began to be explained by relations(hip) of the four natural elements. This interpretation has permeated all languages, and therefore our thinking, for centuries as a natural metaphor. After twenty-five years of research, our team has been able to demonstrate that we can reliably and validly explain much of what is repetitive in our behaviour and experience by four mathematically provable factors whose qualities correspond to the elements. Thus, the Four Elements Inventory has met the American Psychological Association\'s Standards for Psychological and Educational Testing requirements for developers and publishers of psychological tests.',
        ctaHeading: 'Do you want to get your <strong class="font-extrabold">fully personalized development report</strong>?',
        ctaText: 'Get in touch with us',
    }
}
// Add Personal tier data
cz.report[ReportTierEnum.TIER_PERSONAL] = {
    ...en.report[ReportTierEnum.TIER_SUMMARY], ...{
        elementPage: {
            air: {
                headline: 'Develop qualities of <strong>AIR</strong>',
                text: [
                    //
                    '<strong>Treat yourself to relaxation.</strong> Look for opportunities when you can forget about the\n' +
                    'routine of the day and get carried away with stories, fantasies, movies, images,\n' +
                    'dreams… Indulge in aimless activities, roaming or activities you enjoy and which\n' +
                    'inspire you. Let your “airy” component breathe.',
                    //
                    '<strong>Experiment with letting go of control.</strong> What happens if you don’t control certain\n' +
                    'things and don’t bear them in your mind? You may actually find out that not as much\n' +
                    'energy, attention and effort is needed for things running the way you want them.',
                    //
                    '<strong>Look after your spirit and soul</strong> - in other words, after your “air”. The soul isn’t\n' +
                    'focused on performance but on the experience. Look around yourself when you go\n' +
                    'for a trip. Change your plans as you go along – this will teach you not to take the\n' +
                    'destination (result) too seriously and enjoy the path (process).',
                    //
                    '<strong>Create any time the opportunity arises.</strong> Note when and how your ideas come to\n' +
                    'you. Try to change the perspective, experiment with ideas and challenge them. A lot\n' +
                    'of airy qualities are hidden in a joke and humour.',
                ],
            },
            earth: {
                headline: 'Develop qualities of <strong>EARTH</strong>',
                text: [
                    //
                    '<strong>Be observant of your inner process.</strong> To a large extent, bringing up children is\n' +
                    'working with their attention: parents try to disturb their children from their\n' +
                    'absorbedness in their games and turn them to external stimuli, such as dinner time\n' +
                    'or cleaning up toys. Thus, children become more attentive to their surroundings\n' +
                    'and others – and they learn to control their attention in a focused manner.\n' +
                    'Observe your own absorbedness in inner thoughts and feelings. Learn to reflect\n' +
                    'on this process better, step out of it easier, and work with your attention more.',
                    //
                    '<strong>Think about your values.</strong> If you observe your acts\' effects on others, you may\n' +
                    'get surprised by why your behavior bothers them sometimes. For example, people\n' +
                    'often long for certainty, comprehensibility, efficiency, and tangible results. People\n' +
                    'with low Earth representation might inadvertently breach these needs of others as\n' +
                    'they are not aware of them. Your efforts to develop Earth will be easier if you\n' +
                    'understand these values and needs and if you will want to benefit them (or, as the\n' +
                    'case may be, to hamper them less) in achieving them.',
                    //
                    '<strong>Complete your tasks.</strong> Completing a task means assuming the responsibility for\n' +
                    'the result – try to assume it. If one fears the judgment of the result of his/her\n' +
                    'work, he/she may subconsciously keep suspending it. Completing tasks is\n' +
                    'essential for our self-confidence, though. Patience and concentration are needed\n' +
                    'for it. (Especially for the airy people, this may mean much self-denial.) Complete\n' +
                    'your tasks consciously: approach it as a challenge. And observe what energy is\n' +
                    'needed for it. Support your patience and concentration.',
                    //
                    '<strong>Proceed strategically.</strong> If you can step out of your thought flow, you can look at\n' +
                    'yourself and the situation from the outside – and make a strategic reflection. Do I\n' +
                    'realize what goal am I following? Am I using the right path and method to achieve\n' +
                    'it? Am I getting closer? Am I proceeding in compliance with the outside\n' +
                    'circumstances? What effects do my acts achieve?',
                ],
            },
            fire: {
                headline: 'Develop qualities of <strong>FIRE</strong>',
                text: [
                    //
                    '<strong>Discover what you like doing</strong> – and do it.** It is these very activities which fuel\n' +
                    'your inner fire. What we like doing is what “charges our batteries”. We often fail to\n' +
                    'perceive how much energy we are putting into these activities because this\n' +
                    'investment returns big time. The energy gain shows in the feeling of mental strength,\n' +
                    'enthusiasm, fulfilment. We focus outwards more, into the outside world – and that’s\n' +
                    'where we get our energy from.',
                    //
                    '<strong>Make decisions.</strong> Suspended decisions are “unfinished shapes” which retain our\n' +
                    'mental energy. By making decisions you release your energetic capacity.',
                    //
                    '<strong>Focus on your strength,</strong> name them, be well aware of them. Having a healthy selfconfidence\n' +
                    'doesn’t mean being arrogant – it is more about being firm and stable.\n' +
                    'Your strengths can come from various fields of your life. What do others appreciate\n' +
                    'about you? And, most importantly: what do you appreciate about yourself? What\n' +
                    'makes you unique? Think about what you (subconsciously) derive your selfconfidence\n' +
                    'from: how dependent are you in your self-evaluation on the opinions of\n' +
                    'your environment? These can be fickle: observe how the situation you are being\n' +
                    'evaluated in affects your well-being – and your long-term satisfaction. Building your\n' +
                    'self-confidence on more stable foundations means a large gain of personal energy.',
                    //
                    '<strong>Confirm.</strong> If you invest your energy and time into something, be it an activity,\n' +
                    'relationship or contemplation, don’t forget to confirm achieved successes and\n' +
                    'results. Look back on your work, the path you have walked and enjoy the fruit of your\n' +
                    'efforts. Don’t let these moments skip your attention and don’t take success as a matter\n' +
                    'of course.',
                ],
            },
            water: {
                headline: 'Develop qualities of <strong>WATER</strong>',
                text: [
                    //
                    '<strong>Develop mindful approach to inner and outter world.</strong> Make a few “stops”\n' +
                    'throughout the day and ask yourself: How am I feeling right now? What am I\n' +
                    'experiencing? Only if you’re in a good touch with yourself, you will feel comfortable\n' +
                    'and you will have enough energy to enter a good contact with others.',
                    //
                    '<strong>Focus more on the feelings of others.</strong> Ask yourself more often: How do people\n' +
                    'around me feel? What do they experience? What do they fear? What are they\n' +
                    'looking forward to? Talk to people about their feelings. It is the only way to verify the\n' +
                    'truthfulness of your assumptions.',
                    //
                    '<strong>Focus on your relationships with others.</strong> What are they like? Don’t you lack\n' +
                    'depth, intensity or closeness in them? What about the people around you – your\n' +
                    'colleagues, friends, partner – how do they perceive you? What do they think about\n' +
                    'you? Start from here. Especially if your Fire is strong, your self-reflexion might be\n' +
                    'weakened. That’s why you should ask yourself: what do they think about me as a\n' +
                    'person? How do they perceive my acts and words? Ask for a feed-back and\n' +
                    'compare your findings with your own image of yourself. You may find it unnatural or\n' +
                    'unsuitable, yet personal talks and conversations about relationships are the entry gate\n' +
                    'to developing Water.',
                    //
                    '<strong>Expose yourself to feelings and express them.</strong> Seek situations rich in feelings.\n' +
                    'Treat yourself to a romantic film, nice exhibition or another aesthetic experience from\n' +
                    'time to time. Don’t avoid feelings in a conversation; treat yourself to being silent and\n' +
                    'to experiencing what’s going on.Talking about your feelings may sound inappropriate\n' +
                    'at first, yet once you get used to it, you often reveal how beneficial it is in a number\n' +
                    'of situations. By naming your feelings, you find your bearings within yourself and\n' +
                    'your self-confidence grows. Thus, you will start feeling at home in the land of\n' +
                    'feelings and your empathy will start to grow.',
                ],
            },
        },
    }
}
// Add Development tier data
cz.report[ReportTierEnum.TIER_DEVELOPMENT] = {
    ...en.report[ReportTierEnum.TIER_PERSONAL], ...{
        suggestions: {
            headline: '<strong class="font-extrabold">Suggestions</strong> 4 development',
            intro: 'Based on your profile, we have identified <strong class="font-extrabold">suggestions</strong> for your further personal development.'
        }
    }
}

export default cz
