import cz from "./cz"
import en from "./en"
import de from "./de"

const localizations = {
    cs_CZ: cz,
    de_DE: de,
    en_US: en,
}

export default localizations
